/* Built In Imports */
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import React, {
  memo,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';

/* External Imports */
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Select,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { Field, Form, Formik } from 'formik';
import parser from 'html-react-parser';
import { nanoid } from 'nanoid';
import { TbChevronDown } from 'react-icons/tb';
import * as Yup from 'yup';

// import ReCAPTCHA from 'react-google-recaptcha';
const ReCAPTCHA = dynamic(() => import('react-google-recaptcha'));

/* Internal Imports */
import CountriesList from '@constants/CountriesList';
// import { CountriesList } from '@constants/CountriesList';
/* Components */
import config from '@config';
const InputUI = dynamic(() => import('@lb/components/Footer/InputUI'));
// import InputUI from '@lb/components/Footer/InputUI';

//Styles
/* Services */
import { addSubscription } from 'services/newsletterService';

/**
 * Renders the Subscribe Box component
 *
 * @param sectionContent
 * @returns {ReactElement} Subscribe Box component.
 */

const SubscribeBox = ({ sectionContent, lang }) => {
  const router = useRouter();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const finalRef = useRef(null);
  const [messageTemp, setMessageTemp] = useState('');
  const [loading, setLoading] = useState(false);
  const countries = CountriesList;
  // const recaptchaRef = createRef();
  const recaptchaRef = useRef(null);

  React.memo(countries);
  // const isSmallScreen = useMediaQuery({ maxWidth: 340 });
  const initialVal = {
    name: '',
    email: '',
    country: '',
    'opt-in': '',
    newsletters: {},
    source: `${config.BASE_PATH}${router.asPath}`,
  };

  initialVal.newsletters[sectionContent?.newsletterIdentifier] =
    sectionContent?.newsletterIdentifier;

  const ValidationSchema = Yup.object().shape({
    name: Yup.string()
      .matches(/^([a-zA-Z ])+$/u, {
        message: 'Name is invalid',
      })
      .max(100, 'Name is invalid')
      .required('Name is required'),
    email: Yup.string()
      .required('Email is required')
      .matches(/^[a-zA-Z]+[a-zA-Z0-9._]+@[a-zA-Z]+(\.[a-zA-Z]{2,15})+$/, {
        message: 'Email is invalid',
      }),
    country: Yup.string().required('Country is required'),
    recaptcha: Yup.string().required('Cannot go past captcha'),
  });

  const onSubmit = useCallback(
    async (values, { setSubmitting, resetForm, setFieldValue }) => {
      setLoading(true);
      let formValues = values;
      formValues['language'] = lang;
      // setSubmitting(true);
      let formResponse = await addSubscription(formValues);
      setSubmitting(false);
      setFieldValue('recaptcha', '');
      recaptchaRef.current.reset();
      resetForm();

      if (typeof formResponse === 'string') {
        setMessageTemp(
          `<div >Sorry something went wrong. We are not able to process your request right now.</div>`
        );
      } else if (
        formResponse[0]?.status_code === 101 ||
        formResponse[0]?.status_code === 102 ||
        formResponse[0]?.status_code === 103 ||
        formResponse[0]?.status_code === 104 ||
        formResponse[0]?.status_code === 105 ||
        formResponse[0]?.status_code === 106 ||
        formResponse[0]?.status_code === 500
      ) {
        setMessageTemp(formResponse[0]?.message_template);
      } else {
        setMessageTemp(
          `<div >Sorry something went wrong. We are not able to process your request right now.</div>`
        );
      }
      // window.dataLayer = window.dataLayer || [];
      // window.dataLayer.push({
      //   event: 'msr_vp_pushed',
      //   virtual_path: '/shiva_followers_vp_success',
      // });
      onOpen();
      setLoading(false);
      const popupUrl =
        router.asPath.indexOf('?popup=1') > -1
          ? router.asPath
          : router.asPath.indexOf('?') > -1
            ? `${router.asPath}&popup=1`
            : `${router.asPath}?popup=1`;
      window.history.pushState(window.history.state, '', popupUrl);
      window['loc'] = popupUrl;
    },
    [sectionContent]
  );

  useEffect(() => {
    if (!isOpen && window?.loc?.indexOf('popup=1') > -1) {
      const parentUrl =
        location.origin +
        location.pathname +
        location.search.replace(/[\?&]popup=1/, '').replace(/^&/, '?');
      window.history.pushState(window.history.state, '', parentUrl);
    }
    //recaptchaRef?.current?.reset();
  }, [isOpen]);

  useEffect(() => {
    window.onpopstate = e => {
      // consoleLog('onpopstate', router, window, e);
      if (window?.loc?.indexOf('popup=1') > -1) {
        // consoleLog('onpopstate return', router.asPath);
        onClose();
        window['loc'] = router.asPath;
      }
    };
  }, [router.asPath]);
  return (
    <>
      <Flex justifyContent="center" alignItems="center" width="100%">
        <Formik
          initialValues={initialVal}
          validationSchema={ValidationSchema}
          onSubmit={onSubmit}
          enableReinitialize
        >
          {props => (
            <Form
              onSubmit={props.handleSubmit}
              style={{
                rowGap: '21px',
                columnGap: '10px',
                boxSizing: 'border-box',
                placeContent: 'center',
              }}
            >
              <Flex
                flexDirection={{ base: 'column', md: 'row' }}
                mx={{ base: 'auto' }}
                alignItems="center"
                justifyContent="center"
              >
                <Field name="name">
                  {({ field, form }) => (
                    <FormControl
                      isRequired
                      isInvalid={form.errors.name && form.touched.name}
                      display="flex"
                      flexDir="column"
                      fontFamily="FedraSansStd-book"
                    >
                      <InputUI
                        fontFamily="'FedraSansStd-book', sans-serif"
                        color="#605D5D"
                        field={field}
                        id="lastName"
                        maxW={{
                          md: '188px',
                          base: 'max(270px, 90%)',
                        }}
                        height={{ base: '45px', md: '44px' }}
                        placeholder={
                          sectionContent?.content?.json?.name || 'Full Name'
                        }
                        onChange={props.handleChange}
                        padding="20px"
                        borderRadius="6px"
                        _placeholder={{
                          color: '#605D5D',
                          fontSize: '16px',
                        }}
                      />
                      <FormErrorMessage
                        justifyContent="left"
                        position="absolute"
                        margin="0"
                        bottom="-12px"
                        left="5px"
                      >
                        {form.errors.name}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="email">
                  {({ field, form }) => (
                    <FormControl
                      isRequired
                      isInvalid={form.errors.email && form.touched.email}
                    >
                      <InputUI
                        fontFamily="'FedraSansStd-book', sans-serif"
                        color="#605D5D"
                        // border="0.3px solid #000000"
                        field={field}
                        id="email"
                        height={{ base: '45px', md: '44px' }}
                        maxW={{
                          md: '188px',
                          base: 'max(270px, 90%)',
                        }}
                        placeholder="Email"
                        borderRadius="6px"
                        onChange={props.handleChange}
                        padding="20px"
                        _placeholder={{
                          color: '#605D5D',
                          fontSize: '16px',
                        }}
                      />
                      <FormErrorMessage
                        justifyContent="left"
                        position="absolute"
                        margin="0"
                        bottom="-12px"
                        left="5px"
                      >
                        {form.errors.email}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="country">
                  {({ field, form }) => (
                    <FormControl
                      isRequired
                      isInvalid={form.errors.country && form.touched.country}
                      width="100%"
                    // m={{ base: '0 0 10px 0' }}
                    >
                      <Select
                        iconSize="25px"
                        key="country"
                        {...field}
                        id="country"
                        isRequired={true}
                        placeholder={
                          sectionContent?.content?.json?.country || 'Country'
                        }
                        border="none"
                        width={{ base: '100%', md: '188px' }}
                        height={{ base: '45px', md: '44px' }}
                        maxW={{
                          md: '188px',
                          base: 'max(270px, 90%)',
                        }}
                        icon={
                          <Text color="#6A5A46 !important" fontWeight="bold">
                            <TbChevronDown
                              style={{
                                // fontSize: '25px',
                                fontWeight: 'bold',
                              }}
                            />
                          </Text>
                        }
                        rounded="0"
                        margin="4.5px"
                        fontFamily="FedraSansStd-Book"
                        _focus={{}}
                        _hover={{}}
                        bg="#C8B9A6"
                        color="#957F68"
                      >
                        {CountriesList.map(country => {
                          return (
                            <option key={nanoid()} value={country.value}>
                              {country.name}
                            </option>
                          );
                        })}
                      </Select>
                      <FormErrorMessage
                        position="absolute"
                        margin="0"
                        bottom="-17px"
                        left="5px"
                      >
                        {form.errors.country}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </Flex>

              <Flex
                flexDirection={{ base: 'column', md: 'row' }}
                maxW={{ base: 'auto', md: '386px' }}
                mx="auto"
                // maxH={{ base: 'auto', md: '45px' }}
                placeItems="center"
                gridAutoFlow={{ base: 'row', md: 'column' }}
                alignContent="center"
                justifyContent="center"
              >
                <Field name="recaptcha">
                  {({ field, form }) => (
                    <FormControl
                      isInvalid={
                        form.errors.recaptcha && form.touched.recaptcha
                      }
                      m={{ base: '0', md: '0 0px' }}
                      textAlign="center"
                      // height="50px"
                      maxW="290px"
                      w="100%"
                      maxH={20}
                    >
                      <Box
                        mx={{ base: 'auto', md: '0' }}
                        mt={{ base: '0', md: '0', lg: '4px' }}
                        style={{
                          transform: 'scale(0.8)',
                          transformOrigin: '88px 50px',
                        }}
                      >
                        {config.RECAPTCHA_KEY && (
                          <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey={config.RECAPTCHA_KEY}
                            onChange={success => {
                              success === null
                                ? recaptchaRef.current.reset()
                                : props.setFieldValue('recaptcha', success);
                            }}
                            onErrored={error => {
                              props.setFieldValue('recaptcha', '');
                              recaptchaRef.current.reset();
                            }}
                            size="normal"
                          />
                        )}
                      </Box>
                      <FormErrorMessage justifyContent="left">
                        {form.errors.recaptcha}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>

                <Box mt={{ base: '30px', md: '0' }}>
                  <Button
                    type="submit"
                    variant="secondary"
                    w="177px"
                    h="46px"
                    bgColor={'#DB3030'}
                    color="#fff"
                    margin="10px 4.5px"
                    fontWeight={500}
                    disabled={
                      !props.isValid || !props.dirty || loading ? true : false
                    }
                  >
                    <Flex justifyContent="center" alignItems="center">
                      <Text pr={'5px'}>
                        {loading ? 'Submitting..' : 'Subscribe'}
                      </Text>
                    </Flex>
                  </Button>
                </Box>
              </Flex>
            </Form>
          )}
        </Formik>
      </Flex>

      {messageTemp && (
        <Box
          maxW={messageTemp ? { base: '90%', md: '476px' } : 'auto'}
          m="20px auto"
        >
          <Modal
            finalFocusRef={finalRef}
            isOpen={isOpen}
            onClose={onClose}
            isCentered
            motionPreset="slideInBottom"
            size={!messageTemp ? 'xl' : '4xl'}
            blockScrollOnMount={true}
            closeOnOverlayClick={false}
          // scrollBehavior="inside"
          >
            <ModalOverlay />
            <ModalContent p="30px">
              <ModalCloseButton color="#9F9F9F" mt="1" />
              <ModalBody>{parser(messageTemp || '')}</ModalBody>
            </ModalContent>
          </Modal>
        </Box>
      )}
    </>
  );
};

export default memo(SubscribeBox);
